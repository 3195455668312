import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthenticatedCraknClientsEditSfgsConfigEditLocationAccessRoute extends SearchRoute {
  @service store;

  queryParams = {
    page: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true
    },
    archived: {
      refreshModel: true
    }
  }

  defaultPage = 1;

  async model(params) {
    const contractTemplate = this.modelFor('authenticated.crakn.clients.client.sfgs-config.edit');
    const locationTemplates = await this.store.query('v2/locationTemplate', {
      contract_template_id: contractTemplate.id,
      client_id: contractTemplate.clientId,
      location_template_type: 'contract',
      page: params.page || this.defaultPage,
      per_page: params.per_page
    });

    return {
      locationTemplates,
      contractTemplate
    }
  }

  async setupController(controller, model) {
    super.setupController(...arguments);

    controller.setProperties({
      contractTemplate: model.contractTemplate,
      locationTemplates: model.locationTemplates,
      meta: model.locationTemplates.meta
    });

    await controller.setFilteredLocations();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        page: 1,
        meta: null,
        popout_location_name: '',
        popout_organization_id: null
      });
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
